import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  //{path: '/setup', title: 'Setup', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //{path: '/page', title: 'Page', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //{path: '/trucks', title: 'Truck', icon: 'ft-truck', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //{path: '/map', title: 'Map', icon: 'ft-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //{path: '/visualization', title: '3D view', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {path: '/reportes', title: 'Reportes', icon: 'ft-copy', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {path: '/usuarios', title: 'Usuarios', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
];
