
<!-- Sidebar Header starts -->
  <div id="sidebar-background">
    <div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <br>
    <a [routerLink]="['/reportes']" class="logo-text float-left">
      <div class="logo-img">
        <img [src]="logoUrl" alt="Firecatch logo"/>
      </div>
      <span style="text-transform: none;" class="text align-middle" style="color: white;">Firecatch</span>
    </a>
    <br>
  </div>
</div>
<!-- Sidebar Header Ends -->

  <!-- Sidebar Content starts-->
    <div class="sidebar-content main-menu-content" [perfectScrollbar]  [disabled]="!perfectScrollbarEnable">
      <div class="nav-container">
        <ul class="navigation" appSidebarDropdown>
          <!-- First level menu -->
          <br>
            <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.title" [path]="menuItem.path" level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false"
            [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">
            <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
              <i [ngClass]="[menuItem.icon]"></i>
            
          </a>
          <a appSidebarAnchorToggle routerLink="{{menuItem.path}}"
          *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]" style="color: beige;">{{menuItem.badge}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Sidebar Content Ends -->
</div>
