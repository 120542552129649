import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { UserService } from 'app/user.service';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(
    public _firebaseAuth: AngularFireAuth, 
    public router: Router,
    private userService: UserService  
  ) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userService.setCurrentUserBody(user);
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }

  logout() {
    this._firebaseAuth.signOut();
    this.router.navigate(['/pages/login']);
  }

  isAuthenticated() {
    return true;
  }
}
