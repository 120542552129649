import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class routes {
    api = 'api/v1';
    ip_server = '104.154.180.216';
    url_server = "https://app.acot.cl";
    port = '3000';
    protocol = 'http';
    
    comment = 'comments';
    image = 'images';
    report = 'reports';
    user = 'users';
    fcm = 'fcm';

    ip = this.ip_server;

    getUrl(): string {
        return this.url_server;
        //return `${this.protocol}://${this.ip}:${this.port}`;
    }

    commentsUrl(): string {
        return `${this.getUrl()}/${this.api}/${this.comment}`;
    }

    fcmUrl(): string {
        return `${this.getUrl()}/${this.api}/${this.fcm}`
    }

    imagesUrl(): string {
        return `${this.getUrl()}/${this.api}/${this.image}`
    }

    reportsUrl(): string {
        return `${this.getUrl()}/${this.api}/${this.report}`;
    }

    usersUrl(): string {
        return `${this.getUrl()}/${this.api}/${this.user}`;
    }
}