import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    
    const resultReport =[]

    if(arg.length < 3 || arg==='') return value
    for(const report of value){
      if(report.city.toLoweCase().indexOf(arg.toLoweCase()) > -1){
        console.log("buscando")
        resultReport.push(report)
    }
    
  }
  return resultReport;
}
}
