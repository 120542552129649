


<nav class="navbar justify-content-end" style="background-color: #F4F4F5;">
    <ul class="navbar-nav">
      <li class="navbar-item" style="height: 45px; text-align: center; padding: 5px; border-start-start-radius: 15px; border-bottom-left-radius: 15px;">
        <a class="nav-link disabled" style="padding-top: 8px; size: 20px; color:#115a6f" href="#">
          {{user.name}}
        </a>
        <ul>
          <li style="width: 180px; height: 50px; padding-top: 18px; border-radius: 15px; ">
            <a href="" style="text-align: center; ; color:#115a6f">Cerrar Sesion</a>
          </li>
        </ul>
      </li>
      <li class="nav-item" style="width: 80px; height: 45px; text-align: center; padding-right: 15px; border-end-end-radius: 15px; border-top-right-radius: 15px;">
        <a href="#"> 
          <img [attr.src]="addUrl(user.avatar) | safeUrl" style="height: 37px; border-radius: 70%; width: 37px;"/> 
        </a>
        
      </li>
    </ul>
  
  <!--

    <div  style="height: auto; width: auto; padding-top: 5px; padding-right: 5px;">
      
      <div class="card" style="height: auto; width: auto;">
        
        <ul class="navbar-nav ">
          <li class="nav-item" style="text-align: center; padding: 5px;">
            <a class="nav-link disabled" style="size: 20px; color:#115a6f"> {{user.name}} </a>
          </li>
          <li class="nav-item" style="text-align: center; padding-right: 15px;">
            <img [attr.src]="addUrl(user.avatar) | safeUrl" style="height: 37px; border-radius: 70%; width: 37px;"/>
          </li>
        </ul>
        
        
      </div>
    </div>
  -->
</nav>  