import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from './routes';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  base64data;
  defaultAvatar = 'Firecatch_defaultAvatar.svg';
  defaultImage = 'Firecatch_blankImage.png';
  lastUploadedName: string;

  constructor(
    private http: HttpClient,
    private routes: routes
  ) { }

  addUrl(image: string): string {
    return `${this.routes.imagesUrl()}/${image}`
  }

  fileToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(file)
  }

  getBase64Data(): string {
    return this.base64data;
  }

  getDefaultAvatar(): string {
    return this.defaultAvatar;
  }

  getDefaultImage(): string {
    return this.defaultImage;
  }

  getLastUploadedName(): string {
    return this.lastUploadedName;
  }

  handleReaderLoaded(readEvent): void {
    var binaryString = readEvent.target.result;
    this.base64data = btoa(binaryString).split(',')[1];
    console.log(this.base64data);
  }

  SVGtoBase64(source: string): void {
    this.toDataURL(source)
      .then(dataUrl => {
        let url = dataUrl as string
        this.base64data = url.split(',')[1];
      })
  }

  toDataURL = url => fetch(url)
  .then(res => res.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  }));

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    this.http.post<any>(this.routes.imagesUrl(), formData)
      .subscribe(
        (res) => {
          console.log("Filename", res.filename);
          this.lastUploadedName = res.filename
        },
        (err) => console.log(err)
      )
  }

}
