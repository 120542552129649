import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repor-hide',
  templateUrl: './repor-hide.component.html',
  styleUrls: ['./repor-hide.component.scss']
})
export class ReporHideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
