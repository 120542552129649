import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('../../load-files/load-files.module').then(m => m.LoadFilesModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('../../report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  }
];
