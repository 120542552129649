import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImageService } from './image.service';
import { User } from './shared/data/user';
import { routes } from './routes';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUser: User = this.generateEmptyUser()
  FBuser: any;

  constructor(
    private http: HttpClient,
    private imageService: ImageService,
    private routes: routes
  ) { }

  addUser(user: User): Observable<any> {
    return this.http.post<User>(this.routes.usersUrl(), user)
      .pipe(
        catchError(this.handleError('addUser', user))
      );
  }

  extractUser(res): User {
    this.currentUser = res.user;
    console.log(res);
    return res.user;
  }

  extractUsers(res): User[] {
    console.log(res);
    return res.users;
  }

  generateEmptyUser(): User {
    return {
      id: undefined,
      uid: undefined,
      role: 0,
      name: undefined,
      email: undefined,
      password_hash: undefined,
      phone: undefined,
      address: "",
      biography: "",
      notif_dist: 50,
      is_private: true,
      avatar: this.imageService.getDefaultAvatar(),
      reports: [],
      is_blocked: false
    }
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.routes.usersUrl()}/info`)
      .pipe(
        tap(_ => console.log("fetched notifications")),
        map(response => this.extractUsers(response)),
        catchError(this.handleError('getNear', []))
      );
  }

  getUserById(user_id: number): Observable<User> {
    return this.http.get<User>(`${this.routes.usersUrl()}/${user_id}`)
      .pipe(
        tap(_ => console.log("fetched user")),
        map(this.extractUser),
        catchError(this.handleError("getUserById", null))
      )
  }

  getUserByUid(user_uid: string): Observable<User> {
    return this.http.get<User>(`${this.routes.usersUrl()}/FB/${user_uid}`)
      .pipe(
        tap(_ => console.log("fetched user")),
        map(this.extractUser),
        catchError(this.handleError("getUserByUid", null))
      )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error)
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    }
  }

  pushUpdate(): void {
    this.updateUser(this.currentUser)
      .subscribe(res => {console.log(res.message)})
  }

  setCurrentUser(user_uid: string): void {
    this.getUserByUid(user_uid)
        .subscribe(user => {this.currentUser = user;});
  }

  setCurrentUserBody(user): void {
    this.getUserByUid(user.uid)
        .subscribe(
          user => {this.currentUser = user;}
        );
  }

  testPost(): void {
    this.addUser(this.currentUser).subscribe(res => console.log(res.message))
  }

  updateAvatar(avatar: string): void {
    this.currentUser.avatar = avatar;
  }

  updateBiography(biography: string): void {
    this.currentUser.biography = biography;
  }

  updateEmail(email: string): void {
    this.currentUser.email = email;
  }

  updateName(name: string): void {
    this.currentUser.name = name;
  }

  updatePhone(phone: string): void {
    this.currentUser.phone = phone;
  }

  updatePrivacy(is_private: boolean): void {
    this.currentUser.is_private = is_private;
  }

  updateBlocked(is_blocked: boolean): void {
    this.currentUser.is_blocked = is_blocked;
  }

  updateUser(user: User): Observable<any> {
    console.log("user to save: ", user);
    return this.http.put<User>(`${this.routes.usersUrl()}/${user.id}`, user)
    .pipe(
      catchError(this.handleError('updateUser', user))
    );
  } 
}
